<template>
  <div class="v_my_center g-flex-column">
    <head-back :isBack="false">
      <template>
        <span> 我的 </span>
      </template>
    </head-back>
    <div class="v-my-center-container">
      <div class="v-my-center-head g-flex-align-center">
        <img class="v-my-head-image" :src="userInfo.head_img" alt="" @click="uploadAvatarClick()">
        <div class="v-my-head-info-box g-flex-column">
          <p class="v-my-head-id-box">ID:
            <span class="v-my-head-id">{{userInfo.id}}</span>
          </p>
          <p class="v-my-head-id-box">
            手机号:
            <span class="v-my-head-id">{{userInfo.mobile}}</span>
          </p>
          <p class="v-my-head-id-box">
            代理分成:
            <span class="v-my-head-id">{{userInfo.reach_rate}}%</span>
          </p>
        </div>
      </div>
      <!-- 上传头像 -->
      <van-uploader id="uploader-box" :after-read="afterRead" :before-read="beforeRead" v-show="false"></van-uploader>

      <div class="v-my-center-cardone">
        <ul class="v-my-center-cardone-list">
          <li class="v-my-center-cardone-item g-flex-align-center" @click="cashoutClick">
            <div class="v-cardone-item-left g-flex-align-center">
              <i class="iconfont icontixian1"></i> 
              <span class="v-cardone-item-left-text">我要提现</span>
            </div>
            <div class="v-cardone-item-right g-flex-align-center">
              <i class="iconfont iconyou"></i>
            </div>
          </li>
          <li class="v-my-center-cardone-item g-flex-align-center" @click="$router.push({ name: 'cashouthistory' })">
            <div class="v-cardone-item-left g-flex-align-center">
              <i class="iconfont icontixianjilu1"></i> 
              <span class="v-cardone-item-left-text">提现记录</span>
            </div>
            <div class="v-cardone-item-right g-flex-align-center">
              <i class="iconfont iconyou"></i>
            </div>
          </li>
          <li class="v-my-center-cardone-item g-flex-align-center" @click="$router.push({ name: 'moneydetail' })">
            <div class="v-cardone-item-left g-flex-align-center">
              <i class="iconfont iconzijinmingxi"></i> 
              <span class="v-cardone-item-left-text">收益明细</span>
            </div>
            <div class="v-cardone-item-right g-flex-align-center">
              <i class="iconfont iconyou"></i>
            </div>
          </li>
          <li class="v-my-center-cardone-item g-flex-align-center" @click="$router.push({ name: 'changebank' })">
            <div class="v-cardone-item-left g-flex-align-center">
              <i class="iconfont iconyinhangqiabangding"></i> 
              <span  class="v-cardone-item-left-text">银行卡绑定</span>
            </div>
            <div class="v-cardone-item-right g-flex-align-center">
              <span class="v-cardone-item-right-text" v-if="!userInfo.bank">去绑定</span>
              <span class="v-cardone-item-right-text" v-else-if="userInfo.bank.status === 0">审核未通过</span>
              <span class="v-cardone-item-right-text" v-else-if="userInfo.bank.status === 1">审核中</span>
              <span class="v-cardone-item-right-text" v-else-if="userInfo.bank.status === 2">审核通过</span>
              <i class="iconfont iconyou"></i>
            </div>
          </li>
          <li class="v-my-center-cardone-item g-flex-align-center" @click="$router.push({ name: 'changeallpay' })">
            <div class="v-cardone-item-left g-flex-align-center">
              <i class="iconfont iconzhifubao"></i> 
              <span  class="v-cardone-item-left-text">支付宝绑定</span>
            </div>
            <div class="v-cardone-item-right g-flex-align-center">
              <span class="v-cardone-item-right-text" v-if="!userInfo.alipay">去绑定</span>
              <span class="v-cardone-item-right-text" v-else-if="userInfo.alipay.status === 0">审核未通过</span>
              <span class="v-cardone-item-right-text" v-else-if="userInfo.alipay.status === 1">审核中</span>
              <span class="v-cardone-item-right-text" v-else-if="userInfo.alipay.status === 2">审核通过</span>
              <i class="iconfont iconyou"></i>
            </div>
          </li>
        </ul>

      </div>

      <div class="v-my-center-cardtwo">
        <ul class="v-my-center-cardtwo-list">
          <li class="v-my-center-cardtwo-item g-flex-align-center" @click="$router.push({ name: 'changepwd' })">
            <div class="v-cardtwo-item-left g-flex-align-center">
              <i class="iconfont iconxiugaimima1"></i> 
              <span class="v-cardtwo-item-left-text">修改密码</span>
            </div>
            <div class="v-cardtwo-item-right g-flex-align-center">
              <i class="iconfont iconyou"></i>
            </div>
          </li>
          <li class="v-my-center-cardtwo-item g-flex-align-center" @click="copyClick($global.config.AGENT_KEFU.qq)">
            <div class="v-cardtwo-item-left g-flex-align-center">
              <img src="../../assets/img/qq.png" alt=""> 
              <span class="v-cardtwo-item-left-text">QQ客服</span>
            </div>
            <div class="v-cardtwo-item-right g-flex-align-center">
              <span  class="v-cardtwo-item-left-text">{{$global.config.AGENT_KEFU.qq}}</span>
              <i class="iconfont iconyou"></i>
            </div>
          </li>
          <li class="v-my-center-cardtwo-item g-flex-align-center" @click="copyClick($global.config.AGENT_KEFU.wechat)">
            <div class="v-cardtwo-item-left g-flex-align-center">
              <img src="../../assets/img/wechat.png" alt=""> 
              <span  class="v-cardtwo-item-left-text">客服微信</span>
            </div>
            <div class="v-cardtwo-item-right g-flex-align-center">
              <span  class="v-cardtwo-item-left-text">{{$global.config.AGENT_KEFU.wechat}}</span>
              <i class="iconfont iconyou"></i>
            </div>
          </li>
        </ul>
      </div>

      <div class="v-my-center-exit-login">
        <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"/>
      </div>

      <my-center-cash-pop ref="MyCenterCashPop"/>
    </div>

  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { apiGetUserInfo, apiUpLoadHeadImg, apiEditInfo } from '@/utils/api.js'
  import MyCenterCashPop from './MyCenterCashPop.vue'
  // import { ImagePreview } from 'vant';
  import OperationButton from '../../components/OperationButton.vue'
  export default {
    components: { HeadBack, MyCenterCashPop, OperationButton },
    created() {
      this.apiGetUserInfoHandel()
    },
    data() {
      return {
        // principal 本金
        // commission 佣金
        // real_status 0-未实名 1-审核中 2-实名成功 3-审核不通过
        // return_commission 未返佣金
        // return_principal 未返本金

        limitPng: ['image/jpeg','image/jpg','image/png','image/JPEG','image/JPG','image/PNG'],

        userInfo: this.$global.userInfo,
        buttonObj: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '退出登录',
          event: 'exitLogin'
        }
      }
    },
    methods: {
      exitLogin() {
        this.$global.logout()
      },
      emitClickItem(event) {
        if(!event) return
        this[event]()
      },

      // 点击头像
      uploadAvatarClick() {
        document.getElementById('uploader-box').click()
      },

     // 上传之前
      beforeRead(file) {
        if(this.limitPng.indexOf(file.type) === -1) {
          this.$toast('请上传jpeg、jpg、png格式的图片')
          return false
        }
        return true
      },

      // 上传
      async afterRead(file) {
        // formdata提交
        let formData = new FormData()
        formData.append('file', file.file)
        this.$global.loadingShow = true
        const { success, data } = await apiUpLoadHeadImg(formData)
        if(!success) return false
        this.apiEditInfoHandel(data.url)
      },
      
      // 修改头像
      async apiEditInfoHandel(url) {
        this.$global.loadingShow = true
        const { success, data } = await apiEditInfo({ head_img: url })
        if(!success) return
        this.apiGetUserInfoHandel()
        console.log(data)
      },

      // 获取用户信息
      async apiGetUserInfoHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetUserInfo()
        if(!success) return
        this.userInfo = data.data
        this.$global.setUserInfo(data.data)
        console.log(data)
      },
      // 点击提现
      cashoutClick() {
        this.$refs.MyCenterCashPop.onShow()
      },
      copyClick(message) {
        this.$copyText(message).then(() => {
          this.$toast({
            type: 'success',
            message: '复制成功'
          });
        },  () => {
          this.$toast('复制失败,请重试');
        })
      },
    }
  }
</script>

<style lang="scss">
.v_my_center {
  background: #F1F1F1;
  .v-my-center-container {
    flex: 1;
    overflow: auto;
  }
  .v-my-center-head {
    min-height: 128px;
    background: url('/image/daili_my_center_bg.png') no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 20px;
    .v-my-head-image {
      border-radius: 50%;
      width: 66px;
      height: 66px;
      object-fit: cover;
    }
    .v-my-head-info-box {
      .v-my-head-id-box {
        color: $white;
        font-size: 14px;
        flex: 1;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 26px;
        .v-my-head-id {
          letter-spacing: 1px;
          font-size: 18px;
        }
      }
    }

  }


  .v-my-center-cardone {
    padding: 7px 12px 14px 12px;
    .v-my-center-cardone-list {
      background: $white;
      border-radius: 8px;
      .v-my-center-cardone-item {
        padding: 20px 10px;
        border-bottom: 1px solid #DCDCDC;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .v-cardone-item-left {
          flex: 1;
          .iconfont {
            color: $main_color;
            font-size: 18px;
          }
          .v-cardone-item-left-text {
            font-size: 14px;
            padding-left: 10px;
          }
        }

        .v-cardone-item-right {
          padding: 0 10px;
          .v-cardone-item-right-text {
            max-width: 250px;
            text-align: right;
            color: #878787;
            font-size: 14px;
            @include textManyOverflow();
          }
          .iconyou {
            font-size: 22px;
            color: #7D929C;
          }
        }

      }
    }
  }

  .v-my-center-cardtwo {
    padding: 7px 12px 14px 12px;
    .v-my-center-cardtwo-list {
      background: $white;
      border-radius: 8px;
      .v-my-center-cardtwo-item {
        padding: 20px 10px;
        border-bottom: 1px solid #DCDCDC;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .v-cardtwo-item-left {
          flex: 1;
          img {
            width: 19px;
            height: 19px;
            object-fit: cover;
          }
          .iconfont {
            color: $main_color;
            font-size: 18px;
          }
          .v-cardtwo-item-left-text {
            font-size: 14px;
            padding-left: 10px;
          }
        }
        .v-cardtwo-item-right {
          padding: 0 10px;
          .v-cardtwo-item-left-text {
            color: #878787;
            font-size: 14px;
          }
          .iconyou {
            font-size: 22px;
            color: #7D929C;
          }
        }
      }
    }
  }

  .v-my-center-exit-login {
    margin: 0 0 20px 0;
  }
}
</style>